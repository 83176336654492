import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import classnames from 'classnames'

import Button from '~/components/ui/button/button.component'

import { PostType } from '~/types/post.type'
import FC from '~/types/fc'

import CalendarIcon from '~/assets/icons/news-section/calendar.svg'

import styles from './news-item.module.scss'

interface Props {
  content: PostType
  fullwidth?: boolean
}

const NewsItem: FC<Props> = ({ content, fullwidth }) => {
  return (
    <div
      className={
        fullwidth
          ? classnames(styles.root, styles.root__fullwidth)
          : styles.root
      }
    >
      <Img
        fluid={content.cover.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        alt=""
        className={styles.coverBlock}
      />
      <div className={styles.textBlock}>
        <h5 className={styles.title}>{content.title}</h5>
        <p className={styles.description}>{content.description}</p>
        {fullwidth && (
          <Button variant={'secondary'} className={'m-t-m'}>
            Read more
          </Button>
        )}
        {content.eventDate && (
          <div className={styles.itemFooter}>
            <div className={styles.dateRow}>
              <CalendarIcon />
              <p>{content.eventDate}</p>
            </div>
            <div className={styles.categoryLabel}>Event</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default NewsItem
