import React, { useState } from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap'
import classnames from 'classnames'

import Logo from '~/components/ui/logo/logo.component'
import MobileMenu from '~/components/ui/mobile-menu/mobile-menu.component'

import { useScroll } from '~/helpers/useScroll'
import styles from './navbar.module.scss'

import LightOpenIcon from '~/assets/icons/general/menu-open-icon.svg'
import DarkOpenIcon from '~/assets/icons/general/menu-open-icon__dark.svg'

const SNavbar = () => {
  const [isMenuShown, toggleMenu] = useState(false)

  const { scrollY } = useScroll()

  const openClickHandler = () => {
    toggleMenu(true)
  }
  const checkForLinkStyle = () => {
    switch (true) {
      case scrollY > 100:
        return classnames(styles.navLink, styles.navLink__dark)
      default:
        return styles.navLink
    }
  }

  return (
    <Navbar
      fixed="top"
      className={styles.navbar}
      style={
        scrollY > 100
          ? { backgroundColor: 'white' }
          : { backgroundColor: 'transparent' }
      }
    >
      <Container>
        <Logo variant={scrollY > 100 ? 'dark' : 'light'} />
        {scrollY > 100 ? (
          <DarkOpenIcon
            className={styles.openIcon}
            onClick={openClickHandler}
          />
        ) : (
          <LightOpenIcon
            className={styles.openIcon}
            onClick={openClickHandler}
          />
        )}

        <Nav className={classnames(styles.nav, 'mr-auto', 'm-l-l')}>
          <a href="#link" className={checkForLinkStyle()}>
            Workspace
          </a>
          <a href="#link" className={checkForLinkStyle()}>
            Locations
          </a>
          <a href="#link" className={checkForLinkStyle()}>
            Membership
          </a>
          <a href="#link" className={checkForLinkStyle()}>
            Community
          </a>
        </Nav>
        <Nav className={styles.nav}>
          <a href="#link" className={checkForLinkStyle()}>
            Contact
          </a>
          <a href="#link" className={checkForLinkStyle()}>
            Join us
          </a>
          <a href="#link" className={checkForLinkStyle()}>
            Log in
          </a>
        </Nav>
      </Container>
      <MobileMenu isShown={isMenuShown} toggle={toggleMenu} />
    </Navbar>
  )
}

export default SNavbar
