import React from 'react'
import FC from '~/types/fc'
import Img, { FluidObject } from 'gatsby-image'
import styles from './locations-section-card.module.scss'

interface Props {
  imageUrl?: FluidObject
  title?: string
  text?: string
  isOpenSoon?: boolean
}

const LocationsCard: FC<Props> = ({ imageUrl, title, text, isOpenSoon }) => {
  return (
    <div className={styles.locationsCardWrapper}>
      <Img fluid={imageUrl} className={styles.locationsCardImg} />
      <div className={styles.locationsCardTextContainer}>
        <div className={styles.locationsParagraphContainer}>
          <p className={styles.title}>
            <span>{title}</span>
          </p>
          <p className={styles.title}>{text}</p>
        </div>
        {isOpenSoon && <span className={styles.opening}>Opening soon</span>}
      </div>
    </div>
  )
}

export default LocationsCard
