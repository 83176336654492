import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classnames from 'classnames'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'react-bootstrap'

import CommunityOrnament from '~/assets/images/community-section/community-section-ornament.svg'
import Button from '~/components/ui/button/button.component'

import styles from './community-section.module.scss'

const CommunitySection = () => {
  const data = useStaticQuery(graphql`
    query {
      nevilleCard: file(
        relativePath: { eq: "community-section/community-card-first.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 552) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pancyCard: file(
        relativePath: { eq: "community-section/community-card-second.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 552) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container fluid className={styles.outerContainer}>
      <Container className={styles.mainContainer}>
        <CommunityOrnament className={styles.ornament} />
        <Row>
          <Col sm={{ span: 12 }} lg={{ span: 6 }} className={'nopadding'}>
            <div className={styles.leftContainer}>
              <h5>Our customers</h5>
              <h2>A community of original thinkers</h2>
              <p>
                A curated community of artists, innovators and growing creative
                teams, Places members sit at the intersection of culture and
                commerce, redefining the fields of design, publishing, the arts,
                entertainment, and philanthropy.
              </p>
              <Button
                variant={'primary'}
                additionalClasses={[styles.communityButton]}
              >
                Read success stories
              </Button>
            </div>
          </Col>
          <Col
            sm={{ span: 12 }}
            lg={{ span: 6 }}
            className={classnames(styles.rightContainer, 'nopadding')}
          >
            <Img
              fluid={data.nevilleCard.childImageSharp.fluid}
              alt="First opinion card"
              className={styles.firstImg}
            />
            <Img
              fluid={data.pancyCard.childImageSharp.fluid}
              alt="Second opinion card"
              className={styles.secondImg}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
export default CommunitySection
