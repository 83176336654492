import React from 'react'

import styles from './social-media-card.module.scss'

import FacebookIcon from '~/assets/icons/footer/facebook.inline.svg'
import InstagramIcon from '~/assets/icons/footer/instagram.inline.svg'
import TwitterIcon from '~/assets/icons/footer/twitter.inline.svg'
import LinkedinIcon from '~/assets/icons/footer/linkedin.inline.svg'

const SocialMediaCard = () => {
  return (
    <div className={styles.social}>
      <h5>Find us on</h5>
      <div className={styles.socialIcons}>
        <a href="#">
          <FacebookIcon />
        </a>
        <a href="#">
          <InstagramIcon />
        </a>
        <a href="#">
          <TwitterIcon />
        </a>
        <a href="#">
          <LinkedinIcon />
        </a>
      </div>
    </div>
  )
}

export default SocialMediaCard
