import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { useWindowDimensions } from '~/helpers/useWindowDimensions.helper'

import Button from '~/components/ui/button/button.component'
import Input from '~/components/ui/input/input'
import SocialMediaCard from '~/components/ui/social-media-card/social-media-card.component'

import styles from './footer.module.scss'

const Footer = () => {
  const { width } = useWindowDimensions()
  return (
    <Container fluid className={styles.containerOuter}>
      <Container className={styles.mainContainer}>
        <Row>
          <Col xs="12" lg="6" className={styles.inputContainer}>
            <div className={styles.emailWrapper}>
              <h5>Stay up to date with our newsletter</h5>
              <Form className={styles.formWrapper}>
                <Input type="text" placeholder="Your e-mail" />
                <Button
                  variant="primary"
                  type="submit"
                  additionalClasses={styles.footerBtn}
                >
                  Go!
                </Button>
              </Form>
              {width > 992 && <SocialMediaCard />}
            </div>
          </Col>
          <Col xs="6" sm="3" lg="2">
            <div className={styles.linksItem}>
              <h5>Company</h5>
              <a href="#">About</a>
              <a href="#">Locations</a>
              <a href="#">Membership</a>
              <a href="#">Mission</a>
              <a href="#">Career</a>
            </div>
          </Col>
          <Col xs="6" sm="3" lg="2">
            <div className={styles.linksItem}>
              <h5>Community</h5>
              <a href="#">Blog</a>
              <a href="#">Events</a>
              <a href="#">Partners</a>
              <a href="#">Success stories</a>
              <a href="#">Referrals</a>
            </div>
          </Col>
          <Col xs="6" sm="6" lg="2" className={styles.thirdColumn}>
            <div className={styles.linksItem}>
              <h5>Events</h5>
              <a href="#">Host your event</a>
              <a href="#">Catering partners</a>
              <a href="#">Previous clients</a>
              <a href="#">Upcoming events</a>
            </div>
            {width < 992 && <SocialMediaCard />}
          </Col>
        </Row>
        <div className={styles.footerNav}>
          <div>
            <p>
              Made by{' '}
              <span>
                <a href="https://www.unikorns.work/">Unikorns</a>
              </span>
            </p>
          </div>

          <ul className={styles.footerLinks}>
            <li>
              <a href="#">Privacy</a>
            </li>
            <li>
              <a href="#">Terms</a>
            </li>
            <li>
              <a href="#">Cookies</a>
            </li>
            <li>
              <a href="#">Accessibility</a>
            </li>
            <li>
              <a href="#">Sitemap</a>
            </li>
            <li>
              <a href="#">FAQ</a>
            </li>
          </ul>
        </div>
      </Container>
    </Container>
  )
}
export default Footer
