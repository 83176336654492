import React from 'react'
import { Form } from 'react-bootstrap'
import FC from '~/types/fc'

import styles from './input.module.scss'

interface Props {
  type?: string
  placeholder?: string
}

const SInput: FC<Props> = ({ type, placeholder }) => (
  <Form.Control
    type={type}
    placeholder={placeholder}
    className={styles.input}
  />
)

export default SInput
