import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Row, Col, Carousel, CarouselItem } from 'react-bootstrap'

import Navbar from '~/components/ui/navbar/navbar.component'
import HeroCarousel from '~/components/ui/hero-carousel/hero-carousel.component'
import HeroSlide from '~/components/ui/hero-slide/hero-slide.component'
import Button from '~/components/ui/button/button.component'

import BookingLogo from '~/assets/images/hero-section/logos/booking.svg'
import PayPalLogo from '~/assets/images/hero-section/logos/paypal.svg'
import BloombergLogo from '~/assets/images/hero-section/logos/bloomberg.svg'
import FacebookLogo from '~/assets/images/hero-section/logos/facebook.svg'
import SlackLogo from '~/assets/images/hero-section/logos/slack.svg'
import MicrosoftLogo from '~/assets/images/hero-section/logos/microsoft.svg'
import SelectIcon from '~/assets/icons/hero-section/chevron-bottom.svg'

import styles from './hero-section.module.scss'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "hero-section/carousel/slide-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "hero-section/carousel/slide-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "hero-section/carousel/slide-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Navbar />
      <Container fluid className={'nopadding'}>
        <div className={styles.wrapper}>
          <HeroCarousel>
            <CarouselItem>
              <HeroSlide
                imageUrl={data.image1.childImageSharp.fluid}
                location={'Copenhagen, Denmark'}
              />
            </CarouselItem>
            <CarouselItem>
              <HeroSlide
                imageUrl={data.image2.childImageSharp.fluid}
                location={'Copenhagen, Denmark'}
              />
            </CarouselItem>
            <CarouselItem>
              <HeroSlide
                imageUrl={data.image3.childImageSharp.fluid}
                location={'Copenhagen, Denmark'}
              />
            </CarouselItem>
          </HeroCarousel>
          <div className={styles.asideBlock}>
            <div className={styles.topBlock}>
              <h3>Revolutionise your workspace</h3>
              <p>
                Our Spaces are inhabited by forward thinkers, innovators and
                game changers who are confident in achieving their goals.
                Whether you are a small business, entrepreneur or a corporate
                intrapreneur, at Spaces we help our community to expand their
                horizon.
              </p>
              <Row className={styles.topBlockButtonWrapper}>
                <Button
                  variant={'primary'}
                  additionalClasses={[styles.topBlockPrimaryButton]}
                >
                  Book a tour
                </Button>
                <Button
                  variant={'secondary'}
                  additionalClasses={[styles.topBlockSecondaryButton]}
                >
                  Apply now
                </Button>
              </Row>

              <div className={'m-t-xxl'}>
                <h5>Members of our community</h5>
                <Row className={'align-items-center'}>
                  <Col xs="6" md="4" className={'m-t-m'}>
                    <BookingLogo />
                  </Col>
                  <Col xs="6" md="4" className={'m-t-m'}>
                    <PayPalLogo />
                  </Col>
                  <Col xs="6" md="4" className={'m-t-m'}>
                    <BloombergLogo />
                  </Col>
                  <Col xs="6" md="4" className={'m-t-m'}>
                    <FacebookLogo />
                  </Col>
                  <Col xs="6" md="4" className={'m-t-m'}>
                    <SlackLogo />
                  </Col>
                  <Col xs="6" md="4" className={'m-t-m'}>
                    <MicrosoftLogo />
                  </Col>
                </Row>
              </div>
            </div>
            <div className={styles.bottomBlock}>
              <h5>Choose your location</h5>
              <form className={styles.form}>
                <div className={styles.selectWrapper}>
                  <SelectIcon className={styles.selectIcon} />
                  <select className={styles.select}>
                    <option selected value="option1">
                      Marszałkowska Str, Warsaw
                    </option>
                    <option value="option2">Koneser, Warsaw</option>
                    <option value="option3">Platinum, Warsaw</option>
                  </select>
                </div>
                <Button
                  variant={'primary'}
                  additionalClasses={[styles.formButton]}
                >
                  Go
                </Button>
              </form>
              <div className={'m-t-xxl'}>
                <h5>Nearby</h5>
                <p>
                  Koneser, Warsaw
                  <span className={styles.distanceLabel}>7 km</span>
                </p>
                <p>
                  Platinium, Warsaw
                  <span className={styles.distanceLabel}>11 km</span>
                </p>
                <a href="#" className={styles.link}>
                  Show all locations
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}
export default Hero
