import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import Button from '~/components/ui/button/button.component'
import PerksItem from '~/components/ui/perks-item/perks-item.component'

import SupportIcon from '~/assets/icons/perks-section/support-icon.svg'
import MailIcon from '~/assets/icons/perks-section/mailbox-icon.svg'
import QuietIcon from '~/assets/icons/perks-section/quiet-icon.svg'
import PrintingIcon from '~/assets/icons/perks-section/printing-icon.svg'
import WifiIcon from '~/assets/icons/perks-section/wi-fi-icon.svg'
import ServicesIcon from '~/assets/icons/perks-section/kitchen-icon.svg'

import styles from './perks-section.module.scss'

const PerksSection = () => {
  return (
    <Container fluid className={styles.outerContainer}>
      <Container className={styles.mainContainer}>
        <div className={styles.perksDescription}>
          <h2 className={styles.perksTitle}>Work hard, live better</h2>
          <p className={styles.perksHeaderText}>
            More than just an office or business center, Places thrives from the
            interactions that its members have with one another and the space.
          </p>
        </div>
        <Row className={styles.perksRow}>
          <Col lg="2" md="4" xs="6" className={styles.perksItemCard}>
            <PerksItem text={'24/7 secure access'}>
              <SupportIcon className={styles.iconStyles} />
            </PerksItem>
          </Col>
          <Col lg="2" md="4" xs="6" className={styles.perksItemCard}>
            <PerksItem text={'Receive mail & packages'}>
              <MailIcon className={styles.iconStyles} />
            </PerksItem>
          </Col>
          <Col lg="2" md="4" xs="6" className={styles.perksItemCard}>
            <PerksItem text={'Designated quiet work spaces'}>
              <QuietIcon className={styles.iconStyles} />
            </PerksItem>
          </Col>
          <Col lg="2" md="4" xs="6" className={styles.perksItemCard}>
            <PerksItem text={'Printing & Scanning Room'}>
              <PrintingIcon className={styles.iconStyles} />
            </PerksItem>
          </Col>
          <Col lg="2" md="4" xs="6" className={styles.perksItemCard}>
            <PerksItem text={'Super fast Wi-Fi connection'}>
              <WifiIcon className={styles.iconStyles} />
            </PerksItem>
          </Col>
          <Col lg="2" md="4" xs="6" className={styles.perksItemCard}>
            <PerksItem text={'Serviced Kitchens'}>
              <ServicesIcon className={styles.iconStyles} />
            </PerksItem>
          </Col>
        </Row>
        <div className={styles.buttonContainer}>
          <Button variant={'primary'} additionalClasses={[styles.perksButton]}>
            Check our perks & amenities
          </Button>
        </div>
      </Container>
    </Container>
  )
}
export default PerksSection
