import { useState, useEffect } from 'react'

export const useScroll = () => {
  const hasDocument = typeof document !== 'undefined'
  const hasWindow = typeof window !== 'undefined'

  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [bodyOffset, setBodyOffset] = useState(
    hasDocument ? document.body.getBoundingClientRect() : null
  )
  const [scrollY, setScrollY] = useState(hasDocument ? bodyOffset.top : null)
  const [scrollX, setScrollX] = useState(hasDocument ? bodyOffset.left : null)
  const [scrollDirection, setScrollDirection] = useState()

  const listener = () => {
    if (hasDocument) {
      setBodyOffset(document.body.getBoundingClientRect())
      setScrollY(-bodyOffset.top)
      setScrollX(bodyOffset.left)
      setScrollDirection(lastScrollTop > -bodyOffset.top ? 'down' : 'up')
      setLastScrollTop(-bodyOffset.top)
    }
  }

  useEffect(() => {
    if (hasWindow) {
      window.addEventListener('scroll', listener)
      return () => {
        window.removeEventListener('scroll', listener)
      }
    }
  })

  return {
    scrollY,
    scrollX,
    scrollDirection,
  }
}
