import React from 'react'
import { FluidObject } from 'gatsby-image'
import Img from 'gatsby-image/withIEPolyfill'

import FC from '~/types/fc'

import LocationPinIcon from '~/assets/icons/hero-section/location-pin.svg'

import styles from './hero-slide.module.scss'

interface Props {
  imageUrl: FluidObject | FluidObject[]
  location: string
}

const HeroSlide: FC<Props> = ({ imageUrl, location }) => (
  <div className={styles.slide}>
    <Img
      fluid={imageUrl}
      className={styles.slideImg}
      objectFit="cover"
      objectPosition="50% 50%"
    />
    <span className={styles.location}>
      <LocationPinIcon className={'m-x-xs'} />
      <p className={'nopadding nomargin'}>{location}</p>
    </span>
  </div>
)

export default HeroSlide
