import React, { ReactNode } from 'react'
import FC from '~/types/fc'

import styles from './perks-item.module.scss'

interface Props {
  children: ReactNode
  text: string
}

const PerksItem: FC<Props> = ({ children, text }) => {
  return (
    <div>
      {children}
      <p className={styles.perksText}>{text}</p>
    </div>
  )
}
export default PerksItem
