import React from 'react'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Layout from '~/components/layout'
import SEO from '~/components/seo.component'
import Hero from '~/components/sections/hero-section/hero-section.component'
import LocationsSection from '~/components/sections/locations-section/locations-section.component'
import CommunitySection from '~/components/sections/community-section/community-section.component'
import PerksSection from '~/components/sections/perks-section/perks-section.component'
import NewsSection from '~/components/sections/news-section/news-section.component'
import Footer from '~/components/sections/footer/footer.component'

import { PostDataType } from '~/types/post.type'

interface Props {
  data: PostDataType
}

const IndexPage = ({ data }: Props) => (
  <Layout>
    <SEO title="Places Website" />
    <Hero />
    <Fade bottom distance={'30px'}>
      <LocationsSection />
    </Fade>
    <Fade bottom distance={'30px'}>
      <CommunitySection />
    </Fade>
    <Fade bottom distance={'30px'}>
      <PerksSection />
    </Fade>
    <Fade bottom distance={'30px'}>
      {' '}
      <NewsSection blogPosts={data.allStrapiBlogPosts.nodes} />
    </Fade>
    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    allStrapiBlogPosts(limit: 4) {
      nodes {
        id
        title
        description
        link
        eventDate
        cover {
          name
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
