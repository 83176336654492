import React, { useState, ReactNodeArray } from 'react'
import { Carousel } from 'react-bootstrap'

import FC from '~/types/fc'
import { useWindowDimensions } from '~/helpers/useWindowDimensions'

import ArrowLeftIcon from '~/assets/icons/hero-section/arrow-left.svg'
import ArrowRightIcon from '~/assets/icons/hero-section/arrow-right.svg'

interface Props {
  children?: ReactNodeArray
}

const HeroCarousel: FC<Props> = ({ children }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const { width } = useWindowDimensions()
  const handleSelect = (selectedIndex, e) => {
    setActiveItemIndex(selectedIndex)
  }

  return (
    <Carousel
      fade
      wrap
      indicators={width < 767}
      interval={3000}
      activeIndex={activeItemIndex}
      onSelect={handleSelect}
      nextIcon={<ArrowRightIcon />}
      prevIcon={<ArrowLeftIcon />}
    >
      {children}
    </Carousel>
  )
}
export default HeroCarousel
