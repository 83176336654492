import React, { useEffect, useRef } from 'react'
import { Container } from 'react-bootstrap'
import classnames from 'classnames'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

import Logo from '~/components/ui/logo/logo.component'

import FC from '~/types/fc'

import CloseIcon from '~/assets/icons/general/menu-close-icon.svg'

import styles from './mobile-menu.module.scss'

interface Props {
  isShown: boolean
  toggle: (value: boolean) => void
}

const MobileMenu: FC<Props> = ({ isShown, toggle }) => {
  const shownClass = [styles.root, styles.root__shown].join(' ')
  const menuRef = useRef(null)
  useEffect(() => {
    isShown ? disableBodyScroll(menuRef.current) : clearAllBodyScrollLocks()
  }, [isShown])

  const closeClickHandler = (e) => {
    e.preventDefault()
    enableBodyScroll(menuRef.current)
    clearAllBodyScrollLocks()
    toggle(false)
  }

  return (
    <div className={isShown ? shownClass : styles.root} ref={menuRef}>
      <div className={styles.rootWrapper}>
        <Container style={{ padding: 0 }}>
          <Logo variant="light" />
          <CloseIcon onClick={closeClickHandler} />
        </Container>
        <Container className={styles.linkBlock}>
          <a href="#link" className={styles.link}>
            Workspace
          </a>
          <a href="#link" className={styles.link}>
            Locations
          </a>
          <a href="#link" className={styles.link}>
            Membership
          </a>
          <a href="#link" className={styles.link}>
            Community
          </a>
          <a href="#link" className={styles.link}>
            Contact
          </a>
          <a href="#link" className={styles.link}>
            Join us
          </a>
          <a
            href="#link"
            className={classnames(styles.link, styles.link__highlighted)}
          >
            Log in
          </a>
        </Container>
      </div>
    </div>
  )
}

export default MobileMenu
