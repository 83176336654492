import React, { useState, ReactNode } from 'react'
import ItemsCarousel from 'react-items-carousel'
import FC from '~/types/fc'
import { useWindowDimensions } from '~/helpers/useWindowDimensions'

import styles from './location-carousel.module.scss'

interface Props {
  children?: ReactNode
}

const LocationCarousel: FC<Props> = ({ children }) => {
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0)
  const { width } = useWindowDimensions()
  const checkForNumberOfCards = () => {
    switch (true) {
      case width > 1200:
        return 3
      case width > 576:
        return 2
      default:
        return 1
    }
  }
  return (
    <div className={styles.root}>
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={checkForNumberOfCards()}
        slidesToScroll={1}
        showSlither
        gutter={13}
        firstAndLastGutter={width < 1200 || (width > 576 && true)}
        classes={{
          wrapper: '',
          itemsWrapper: styles.itemsWrapper,
        }}
      >
        {children}
      </ItemsCarousel>
    </div>
  )
}

export default LocationCarousel
