import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import FC from '~/types/fc'
import { PostType } from '~/types/post.type'

import NewsItem from '~/components/ui/news-item/news-item.component'
import Media from 'react-media'

import styles from './news-section.module.scss'
import Button from '~/components/ui/button/button.component'

interface Props {
  blogPosts: PostType[]
}
const NewsSection: FC<Props> = ({ blogPosts }) => {
  const [posts, setPosts] = useState([])
  useEffect(() => {
    setTimeout(() => {
      setPosts(
        blogPosts.map((post: PostType, index) => {
          if (index < 4) {
            if (index === 0) {
              return (
                <Col
                  key={post.id}
                  xs="12"
                  md="6"
                  lg="12"
                  className={'d-flex m-t-xl'}
                >
                  <a href="#" className={styles.linkUnstyled}>
                    <Media query={{ maxWidth: 991 }}>
                      {(matches) =>
                        matches ? (
                          <NewsItem content={post} />
                        ) : (
                          <NewsItem content={post} fullwidth />
                        )
                      }
                    </Media>
                  </a>
                </Col>
              )
            } else {
              return (
                <Col
                  key={post.id}
                  xs="12"
                  md="6"
                  lg="4"
                  className={'d-flex m-t-xl'}
                >
                  <a href="#" className={styles.linkUnstyled}>
                    <NewsItem content={post} />
                  </a>
                </Col>
              )
            }
          }
        })
      )
    }, 300)
  }, [])
  return (
    <Container className={styles.root}>
      <h2>News, updates and insights</h2>
      <Row className={'m-t-l'}>{posts}</Row>
      <div className={styles.buttonContainer}>
        <Button
          variant={'primary'}
          additionalClasses={[styles.locationsButton]}
        >
          View all locations
        </Button>
      </div>
    </Container>
  )
}

export default NewsSection
