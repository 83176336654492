import React from 'react'
import { Link } from 'gatsby'

import DarkLogotype from '~/assets/images/general/dark-places-logo.svg'
import LightLogotype from '~/assets/images/general/light-places-logo.svg'

import FC from '~/types/fc'

import styles from './logo.module.scss'

interface Props {
  variant: string
}

const Logo: FC<Props> = ({ variant }) => {
  let variantLogo
  switch (variant) {
    case 'dark':
      variantLogo = <DarkLogotype className={styles.logo} />
      break
    case 'light':
      variantLogo = <LightLogotype className={styles.logo} />
      break
    default:
      variantLogo = <DarkLogotype className={styles.logo} />
      break
  }
  return <Link to="/">{variantLogo}</Link>
}

export default Logo
