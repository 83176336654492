import React from 'react'
import { Container } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import styles from './locations-section.module.scss'

import LocationsCard from '~/components/ui/locations-section-card/locations-section-card.component'

import Carousel from '~/components/ui/location-carousel/location-carousel.component'

import Button from '~/components/ui/button/button.component'

const LocationsSection = () => {
  const data = useStaticQuery(graphql`
    query {
      newYorkImg: file(
        relativePath: { eq: "locations-section/madison-square-img.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 358) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      veniceImg: file(
        relativePath: { eq: "locations-section/venice-img.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 358) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      losAngelesImg: file(
        relativePath: { eq: "locations-section/bradbury-img.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 358) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container fluid className={styles.root}>
      <div className={styles.mainTitle}>
        <h2>Where culture and commerce collide</h2>
        <p>
          Places is the private workspace and cultural home for creators,
          innovators, and thought leaders. With iconic buildings, timeless
          design, thought-provoking cultural experiences, and elevated
          hospitality, NeueHouse has reimagined the ideal environment for
          creative performance and progress.
        </p>
      </div>
      <Carousel>
        <LocationsCard
          imageUrl={data.newYorkImg.childImageSharp.fluid}
          title={'Madison Square'}
          text={'New York'}
          isOpenSoon
        />
        <LocationsCard
          imageUrl={data.veniceImg.childImageSharp.fluid}
          title={'Venice'}
          text={'Venice Beach'}
        />
        <LocationsCard
          imageUrl={data.losAngelesImg.childImageSharp.fluid}
          title={'Bradbury'}
          text={'Downtown Los Angeles'}
        />
      </Carousel>
      <div className={styles.buttonContainer}>
        <Button
          variant={'primary'}
          additionalClasses={[styles.locationsButton]}
        >
          View all locations
        </Button>
      </div>
    </Container>
  )
}
export default LocationsSection
